import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { withPrefix, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import FeaturedSection from '../components/FeaturedSection';
import {
  COLOR_GREEN,
  MAX_WIDTH,
  MAX_WIDTH_SITE,
  COLOR_GRAY,
  COLOR_TEXT_GRAY,
  MAIN_HEADING_FONT,
  screen,
} from '../components/common/variables';
import peter from '../images/about-peter-cruwys.jpg';
import logo from '../images/about-our-logo.jpg';

const Banner = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
  @media ${screen.largeXL} {
    padding-top: 0;
  }

  .small-banner {
    max-width: 100%;
    @media ${screen.largeXL} {
      display: none;
    }
  }
  .large-banner {
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  max-width: ${MAX_WIDTH};

  .sub-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    @media ${screen.medium} {
      flex-wrap: nowrap;
    }
    @media ${screen.largeXL} {
      justify-content: space-between;
    }

    .text-container {
      @media ${screen.large} {
        width: 50%;
      }
    }

    .peter {
      max-width: 100%;
      order: -1;
      padding-bottom: 20px;
      @media ${screen.small} {
        padding-bottom: 0;
      }
      @media ${screen.medium} {
        order: 1;
      }
      img {
        width: 100%;
        max-width: 400px;
        @media ${screen.medium} {
          max-width: 300px;
          margin-top: 80px;
        }
        @media ${screen.largeXL} {
          max-width: 450px;
          margin-top: 100px;
        }
      }
    }

    .logo {
      max-width: 100%;
      img {
        width: 100%;
        max-width: 400px;
        @media ${screen.medium} {
          max-width: 300px;
        }
        @media ${screen.largeXL} {
          max-width: 450px;
          margin-top: 4rem;
        }
      }
    }
  }

  margin: 0 auto 45px auto;
  @media ${screen.largeXL} {
    margin: 0 auto 90px auto;
  }

  .about {
    padding: 0 35px;
    @media ${screen.largeXXL} {
      padding: 0;
    }

    &__page-title {
      font-size: 1.8rem;
      font-weight: 400;
      margin-bottom: 20px;
      color: ${COLOR_GRAY};
      @media ${screen.medium} {
        margin-bottom: 20px;
        font-size: 2.1rem;
      }
      @media ${screen.largeXL} {
        font-size: 2.4rem;
      }
    }

    &__text-intro {
      margin: 45px 0;
      max-width: 710px;
      color: ${COLOR_GRAY};
      ${MAIN_HEADING_FONT};
      h2,
      p,
      b {
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.25;
        @media ${screen.large} {
          font-size: 1.8rem;
          margin: 60px 0;
        }
      }
      em {
        color: ${COLOR_GREEN};
        font-style: normal;
      }
    }

    &__content {
      p {
        font-size: 1rem;
        max-width: 700px;
        margin-bottom: 15px;
        color: ${COLOR_TEXT_GRAY};
        @media ${screen.medium} {
          margin-bottom: 30px;
          font-size: 1.1rem;
        }
      }
    }
  }
`;

const AboutPage = ({ data }) => {
  const { acfAboutPage } = data.content;
  const pageTitle =
    (acfAboutPage.seo && acfAboutPage.seo.page_title) || 'About';
  const pageDescription = acfAboutPage.seo && acfAboutPage.seo.description;

  return (
    <Layout>
      <SEO
        title={pageTitle}
        description={pageDescription}
        image={withPrefix('/og_images/about.jpeg')}
      />
      <Banner>
        <GatsbyImage
          className="large-banner"
          image={
            data.content.acfAboutPage.imageBanner.localFile.childImageSharp
              .gatsbyImageData
          }
          alt={
            data.content.acfAboutPage.imageBanner.altText ||
            'Source Separation Systems'
          }
        />

        <div className="small-banner">
          <GatsbyImage
            image={
              data.content.acfAboutPage.imageBannermobile.localFile
                .childImageSharp.gatsbyImageData
            }
            alt={
              data.content.acfAboutPage.imageBannermobile.altText ||
              'Source Separation Systems'
            }
          />
        </div>
      </Banner>
      <Wrapper>
        <div className="about">
          <div
            className="about__text-intro"
            dangerouslySetInnerHTML={{
              __html: data.content.acfAboutPage.headlineIntroduction,
            }}
          />

          <div className="sub-container">
            <div className="text-container">
              <h1 className="about__page-title">About us</h1>
              <div
                className="about__content"
                dangerouslySetInnerHTML={{ __html: acfAboutPage.aboutUs }}
              />
            </div>
            <div className="peter">
              <img src={peter} alt="About Peter Cruwys" />
            </div>
          </div>

          <div className="sub-container">
            <div className="logo">
              <img src={logo} alt="About Our Logo" />
            </div>
            <div className="text-container">
              <h1 className="about__page-title">Our logo is us!</h1>
              <div
                className="about__content"
                dangerouslySetInnerHTML={{
                  __html: acfAboutPage.aboutOurLogo,
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
      <FeaturedSection content={data.featuredContent.edges} />
    </Layout>
  );
};

export default AboutPage;

export const aboutData = graphql`
  {
    content: wpPage(id: { eq: "cG9zdDoxMA==" }) {
      id
      acfAboutPage {
        seo {
          pageTitle
          description
        }
        aboutUs
        aboutOurLogo
        headlineIntroduction
        imageBanner {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        imageBannermobile {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1080, height: 624)
            }
          }
        }
      }
    }

    featuredContent: allWpPost(
      limit: 3
      sort: { fields: modified, order: DESC }
      filter: { tags: { nodes: { elemMatch: { name: { eq: "About" } } } } }
    ) {
      edges {
        node {
          id
          title
          slug
          acfBlog {
            content
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 670, height: 490)
                }
              }
            }
          }
        }
      }
    }
  }
`;
